/* You can add global styles to this file, and also import other style files */

/* Importing Bootstrap SCSS file. */
@import "bootstrap/scss/bootstrap";
@import "ngx-toastr/toastr";

body {
  display: flex;
  flex-direction: column;
  min-height: 100vh;
  font-size: 16px;
  overflow-y: auto;
  min-height: 100%;
  background: url(assets/img/bgshapes-blue-1.png) repeat-x center top,
    url(assets/img/bgshapes-blue-2.png) repeat-x center bottom;
  background-attachment: fixed, fixed;
  background-color: #ebf4f8;
}
main {
  flex-grow: 1;
}
.kg-color-yellow {
  color: #f7c600;
}
.kg-color-white {
  color: white;
}
.btn-kg-color-yellow,
.btn-kg-color-yellow:hover,
.btn-kg-color-yellow:active,
.btn-kg-color-yellow:visited,
.btn-kg-color-yellow:focus {
  background-color: #f7c600 !important;
  border: #f7c600 !important;
  color: white;
}
.kg-border-yellow {
  border: 3px solid #f7c600;
}
.btn-kg-color-pink,
.btn-kg-color-pink:hover,
.btn-kg-color-pink:active,
.btn-kg-color-pink:visited,
.btn-kg-color-pink:focus {
  background-color: #fe4b7b !important;
  border: #fe4b7b !important;
  color: white;
}
.kg-border-pink {
  border: 3px solid #fe4b7b !important;
}
.btn-kg-color-blue,
.btn-kg-color-blue:hover,
.btn-kg-color-blue:active,
.btn-kg-color-blue:visited,
.btn-kg-color-green:focus {
  background-color: #48cae4 !important;
  border: #48cae4 !important;
  color: white;
}
.btn-kg-color-orange,
.btn-kg-color-orange:hover,
.btn-kg-color-orange:active,
.btn-kg-color-orange:visited,
.btn-kg-color-orange:focus {
  background-color: #e87d64 !important;
  border: #e87d64 !important;
  color: white;
}

.kg-border-blue {
  border: 3px solid #48cae4;
}
.kg-color-blue {
  color: #48cae4;
}
h1,
h5,
p {
  text-align: center;
}

.card {
  border-radius: 0 !important;
}

.card-img,
.card-img-top {
  border-top-left-radius: 0 !important;
  border-top-right-radius: 0 !important;
}
.card-header:first-child {
  border-radius: 0 !important;
}
.heading {
  font-size: 36px !important;
  font-weight: 700 !important;
  color: #00204d !important;
}
p {
  font-size: 16px;
  font-weight: 700;
  color: #00204d;
}
.text-left {
  text-align: left;
}
.pt-16 {
  padding-top: 16px;
}
.mt-16 {
  padding-top: 16px;
}
.font-w-700 {
  font-weight: 700;
}
.bgcard {
  min-height: 500px;
  border: none;
  border-radius: 10px;
  background-color: rgba(255, 255, 255, 0.75);
  box-shadow: 0 0.125rem 0.25rem rgba(0, 0, 0, 0.075) !important;
}
.color-deepblue {
  color: #00204d;
}
.color-light-grey {
  color: #999999 !important;
}
.font-12 {
  font-size: 12px !important;
}
.pt-8 {
  padding-top: 8px;
}
.toast-title {
  font-size: larger;
  margin-bottom: 10px;
}
.toast-message {
  font-size: large;
}
.toast-top-center {
  margin-top: 20px;
}
app-entertainment {
  padding: 0 !important;
}
